<template>
  <el-dialog
    title=""
    custom-class="login-form"
    :visible.sync="loginStatus"
    :before-close="beforeClose"
  >
    <div class="login-theClues">WELCOME</div>
    <div class="login">
      <div class="contleft">
        <el-form
          ref="loginForm"
          :rules="rules"
          :model="loginForm"
          label-width="0px"
        >
          <el-form-item prop="email">
            <el-input
              v-model="loginForm.email"
              :placeholder="$t('m.email')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              show-password
              v-model="loginForm.password"
              placeholder="password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="success"
              :loading="loading"
              class="login-btn"
              @click="setSubmit"
              >Now Login</el-button
            >
          </el-form-item>
        </el-form>
        <div class="registered">
          <el-button type="text" @click="forgotpassword"
            >Forgot password？</el-button
          >
          <el-button type="text" @click="register">Create an account</el-button>
        </div>
      </div>
      <div class="centerline"></div>
      <div class="contright">
        <div class="text">
          <div class="icon">
            <img src="@/assets/img/yuding.png" alt="" />
          </div>
          More convenient booking experience
        </div>
        <div class="text">
          <div class="icon">
            <img src="@/assets/img/shuaxin.png" alt="" />
          </div>
          More comprehensive viewing of orders
        </div>
        <div class="text">
          <div class="icon">
            <img src="@/assets/img/zhekou.png" alt="" />
          </div>
          More discount information
        </div>
        <div class="btnbox">
          <div class="btn" @click="next()">No, just book it</div>
        </div>
      </div>
      <div class="phonefo">
        <el-divider>Or</el-divider>
        <a class="tiaozhuan" @click="next()">No, just book it ></a>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { emailRules } from "../../utils/rules";
import { login, saveParams } from "@/api/index";
import eventBus from "./eventBus";
import CookiesUtils from "@/utils/cookies";

export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      loading: false,
      rules: {
        email: [{ validator: emailRules(), trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },
    };
  },
  computed: {
    loginStatus() {
      return this.$store.state.login.loginStatus;
    },
    atCurrency() {
      return this.$store.state.common.Currency;
    },
  },
  created() {
    // // console.log(this.$route.name);
    eventBus.$off("goLogin");
    eventBus.$on("goLogin", ({ email, password }) => {
      this.$set(this.loginForm, "email", email);
      this.$set(this.loginForm, "password", password);
      this.setSubmit();
    });
  },
  methods: {
    next() {
      const searchForm = JSON.parse(
        window.sessionStorage.getItem("searchForm")
      );
      const isSelectClick = window.sessionStorage.getItem("isSelectClick");
      const totalPriceEx = window.sessionStorage.getItem("totalPriceEx");
      this.$store.dispatch("login/set_loginstatus", false);
      if (isSelectClick != 1) return;
      // this.$router.push({
      //   path: `/information/${window.sessionStorage.getItem("toData")}`,
      //   query: {
      //     ...searchForm,
      //     totalPriceEx,
      //   },
      // });
      // console.log(this.atCurrency);
      saveParams({
        Currency: this.atCurrency,
        adultNumber: searchForm.adultNumber,
        childNumber: searchForm.childNumber,
        data: window.sessionStorage.getItem("toData"),
        kind: searchForm.kind,
        totalPriceEx: totalPriceEx,
      }).then((res) => {
        // // console.log(res.data);
        if (res.data.code == 200) {
          // // console.log(res.data);
          this.$router.push({
            path: `/information/${res.data.data}`,
          });
        } else {
          const msgType = res.code === 200 ? "success" : "error";
          // this.$message[msgType](res.msg);
          this.$notify({
            title: msgType,
            message: res.msg,
            type: msgType,
          });
        }
      });
    },
    beforeClose() {
      this.$store.dispatch("login/set_loginstatus", false);
    },
    setSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.goSubmit();
        } else {
          return false;
        }
      });
    },
    goSubmit() {
      this.loading = true;
      login({
        mail: this.loginForm.email,
        password: this.loginForm.password,
      }).then((res) => {
        const result = res.data;
        if (res.status == 200) {
          if (result.code === 200) {
            CookiesUtils.set("token", res.headers.authorization);
            this.$store.dispatch("login/set_userInfo");
            const toInformation =
              window.sessionStorage.getItem("toInformation");
            if (this.$route.name === "flights" && toInformation) {
              window.sessionStorage.removeItem("toInformation");
              this.next();
            }
            this.$store.dispatch("login/set_loginstatus", false);
            this.$store.dispatch("login/set_loginshow", false);
          }
          this.loading = false;
        } else if (res.status == 301) {
          // this.$message.error("Password error");
          this.$notify({
            title: "error",
            message: "Password error",
            type: "error",
          });
        }
        this.loading = false;
      });
    },
    register() {
      this.$store.dispatch("register/set_registerStatus", true);
    },
    forgotpassword() {
      this.$store.dispatch("register/set_forgotPwdStatus", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./login.scss";
</style>
