import Cookies from "js-cookie";

class CookiesUtils {
  static get(key) {
    return Cookies.get(key);
  }
  static set(key, value) {
    return Cookies.set(key, value, { expires: 1 });
  }
  static remove(key) {
    return Cookies.remove(key);
  }
}

export default CookiesUtils;
