<template>
  <el-dialog
    :title="title"
    center
    custom-class="agreement-form"
    :visible.sync="agreementStatus"
    :before-close="beforeClose"
  >
    <!-- <p class="title">Legal Notices</p> -->
    <div v-html="htmlText"></div>
  </el-dialog>
</template>

<script>
import { getNotices } from "@/api/index";
export default {
  name: "agreement",
  data() {
    return {
      title: this.$t("m.contentTitle"),
      htmlText: "",
    };
  },
  computed: {
    agreementStatus() {
      return this.$store.state.register.agreementStatus;
    },
  },
  created() {
    this.getTerms();
  },

  methods: {
    beforeClose() {
      this.$store.dispatch("register/set_agreementStatus", false);
    },
    //获取terms
    getTerms() {
      getNotices().then((res) => {
        // console.log(res);
        this.htmlText = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.agreement-form.el-dialog {
  width: 32%;
  height: 68%;
  margin-top: 3rem !important;
  overflow: hidden;
  @extend %f-d-c;
  .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 0rem 0.4rem;
    > div {
      font-size: 0.24rem;
    }
  }
  .el-dialog__title {
    font-size: 32px;
    font-weight: 600;
  }
  p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1680px) {
  .el-dialog.agreement-form {
    width: 42%;
  }
}

@media screen and (max-width: 1200px) {
  .el-dialog.agreement-form {
    width: 50%;
  }
}
@media screen and (max-width: 960px) {
  .el-dialog.agreement-form {
    width: 40%;
  }
}

@media screen and (max-width: 766px) {
  .el-dialog.agreement-form {
    width: 80%;
  }
}
</style>
