<template>
  <el-dialog
    title=""
    custom-class="register-form"
    :visible.sync="resetPwdStatus"
    v-if="resetPwdStatus"
    :before-close="beforeClose"
  >
    <div class="register">
      <div class="register-theClues">Create an account</div>
      <el-form
        ref="registerForm"
        :rules="rules"
        :model="registerForm"
        label-width="0px"
      >
        <el-form-item prop="resetPassword">
          <el-input
            v-model="registerForm.resetPassword"
            show-password
            placeholder="resetPassword"
          ></el-input>
        </el-form-item>
        <el-form-item prop="resetPassword1">
          <el-input
            v-model="registerForm.resetPassword1"
            show-password
            placeholder="resetPassword1"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="success"
            :loading="loading"
            class="register-btn"
            @click="setSubmit"
            >Next</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { resetting } from "@/api/index";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter your password again"));
      } else if (value !== this.registerForm.resetPassword1) {
        callback(new Error("Two input password is inconsistent!"));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        registerEmail: window.sessionStorage.getItem("mail"),
        resetPassword: "",
        resetPassword1: "",
      },
      loading: false,
      codeLoading: false,
      rules: {
        resetPassword: [
          {
            required: true,
            message: "Please repeat the password",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
        resetPassword1: [
          {
            required: true,
            message: "Please repeat the password",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      timer: null,
      num: 60,
      isCode: false,
    };
  },
  computed: {
    resetPwdStatus() {
      return this.$store.state.register.resetPwdStatus;
    },
  },
  methods: {
    beforeClose() {
      this.$store.dispatch("register/set_resetPwdStatus", false);
    },
    setSubmit() {
      this.$refs["registerForm"].validate((valid) => {
        if (valid) {
          this.goSubmit();
        } else {
          return false;
        }
      });
    },
    goSubmit() {
      resetting({
        mail: this.registerForm.registerEmail,
        PassWord: this.registerForm.resetPassword,
      }).then((res) => {
        const results = res.data;
        const Statuing = JSON.parse(results.match(/(.*?){"d":null}/)[1]);
        if (Statuing.status == 200) {
          this.$alert("Reset succeeded, Jump back to the login page", {
            confirmButtonText: "Confirm",
          }).then(() => {
            this.$store.dispatch("register/set_forgotPwdStatus", false);
            this.$store.dispatch("register/set_resetPwdStatus", false);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./resetPassword.scss";
</style>

