import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  registerStatus: false, // 是否展示登录框
  agreementStatus: false, // 协议框是否展示
  forgotPwdStatus: false, // 忘记密码弹框
  resetPwdStatus: false, // 重置密码弹框
  editPwdStatus: false// 修改密码弹框
};
const mutations = {
  SET_REGISTERSTATUS(state, value) {
    state.registerStatus = value;
  },
  SET_AGREEMENTSTATUS(state, value) {
    state.agreementStatus = value;
  },
  SET_FORGOTPWDSTATUS(state, value) {
    state.forgotPwdStatus = value;
  },
  SET_RESETPWDSTATUS(state, value) {
    state.resetPwdStatus = value;
  },
  SET_EDITPWDSTATUS(state, value) {
    state.editPwdStatus = value;
  },
};
const actions = {
  set_registerStatus(context, value) {
    context.commit("SET_REGISTERSTATUS", value);
  },
  set_agreementStatus(context, value) {
    context.commit("SET_AGREEMENTSTATUS", value);
  },
  set_forgotPwdStatus(context, value) {
    context.commit("SET_FORGOTPWDSTATUS", value);
  },
  set_resetPwdStatus(context, value) {
    context.commit("SET_RESETPWDSTATUS", value);
  },
  set_editPwdStatus(context, value) {
    context.commit("SET_EDITPWDSTATUS", value);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
