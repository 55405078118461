export const m = {
  //导航栏
  home: 'Home',
  order: 'Order',
  contact: 'Contact',
  service: 'service@etrippro.com',
  navlogin: 'Login',
  personalCenter: 'Personal Center',
  Currency: 'Currency',
  myOrder: 'My Order',
  personal: 'Information',
  modify: 'Modifiy',
  out: 'Out Login',
  //index  内容区域
  title: 'Easy To Book At Unbeatable Prices',
  oneWay: 'One Way',
  Return: 'Return',
  fromCity: 'From City',
  to: 'To City',
  departrueDate: 'Departrue Date',
  Passenger: 'Passenger',
  Adult: 'Adult',
  children: 'Child',
  age: ' (>12)',
  childAge: ' (2-12)',
  regulations: '1 adult can only carry 2 children',
  complete: 'OK',
  hot: 'Top destinations right now',
  Initially: 'From City or Airport',
  destination: 'To City or Airport',
  trip: 'Depart',
  returnTrip: 'Return',
  search: 'Search',
  Typeoftravel: 'Trip Type',
  travelContent: 'One-Way',
  travelContents: 'Roundtrip',
  Cabintype: 'Cabin',
  Cabinchoice: 'Economy',
  Cabinchoices: 'Premium',
  discount: 'Exclusive travel discounts',
  discountDetails: 'We negotiate directly with airlines and hotel suppliers to get you exclusive discounts',
  discountUp: 'Double up on savings',
  discountupDetails: 'Get promo codes on top of everyday discounts during peak booking times',
  Norisk: 'Risk-free cancellation‡',
  NoriskDetails: 'Lock in that great price as you can cancel many flights for free within 24 hours and many hotels up until 24 hours before arrival‡',
  // discount: '',
  // discount: '',
  // footer
  hours: 'Exclusive travel discounts',
  onLine: 'We negotiate directly with airlines and hotel suppliers to get you exclusive discounts',
  security: 'Double up on savings',
  secure: 'Get promo codes on top of everyday discounts during peak booking times',
  global: 'Risk-free cancellation‡',
  route: 'Lock in that great price as you can cancel many flights for free within 24 hours and many hotels up until 24 hours before arrival‡',
  aboutUs: 'About Us',
  himytrip: 'About Himytirp',
  Changes: 'Changes Cancellation and Refund',
  contactUs: 'Learn More',
  learnMore: 'Terms of Service',
  TermsOfService: 'Terms and Conditions',
  statement: 'Privacy',
  containerTitle: 'Easy To Book At Unbeatable Prices',
  // 登录部分
  welcome: 'WELCOME',
  login: 'Login',
  email: 'Email',
  password: 'Password',
  forget: 'Forget the password？',
  now: 'Now Login',
  noAccount: 'No account',
  register: 'register',
  //找回密码
  retrieve: 'Retrieve the password',
  Registered: 'Registered mailbox',
  Verification: 'Verification',
  obtain: 'Verification',
  next:"Next",
  //用户注册部分
  registerUser: 'User registration',
  registerEmail: 'Email',
  registerPass: 'Password',
  registerAgain: 'Confirm Password',
  // Verification: 'Verification Code',
  // obtain: 'Verification',
  agree: 'Agree《Himytripclause》',
  existing: 'Existing accounts，',
  // now: 'Now Login',

// 购票列表侧边栏
  PriceTitle: 'Price',
  included: 'Include Tax',
  NoTax: 'No Tax',

  Departure: 'Departure',
  AM: 'AM',
  PM: 'PM',
  Evening: 'Evening',

  Arrive: 'Arrive',


  Transit: 'Transit',
  Direct: 'Direct',
  TransitOne: 'Transit One',
  Twiceormore: 'Twice or more',

  Airport: 'Airport',
  airports: 'Charles De Gaulle Airport',
  Airlines: 'Airlines',
  Airline1: 'AIR FRANCE',
  Airline2: 'KLM-ROYALDUTCH AIRLINES',
  Airline3: 'DELTA AIRLINES',
  Airline4: 'UNITED AIRLINES',
  Airline5: 'AIR CANADA',
  Airline6: 'BRITISH AIRLINE',


  //购票列表

  Airline6: 'Paris Charles de Gaulle Airport',
  Airportfrom: 'Charles De Gaulle Airport',
  Airlineto: 'Pearson Airport',
  transfer: 'Amsterdam-Schiphol Airport',
  Economy: 'Economy',
  OneWays: 'One Way',
  transferNum: 'Transit one',
  Buy: 'Buy',
  Detailed: 'Detailed',
  from: 'From',
  // 下拉框
  tripfrom: 'trip',
  tripTo: 'Paris  -  Toronto',
  detailsairport: 'AIR FRANCE    AF8224',
  CharlesDeGaulleAirport: 'Charles De Gaulle AirportT2F',
  AmsterdamSchipholAirport: 'Amsterdam-Schiphol Airport',
  flight: 'Flight time：1h25m',
  CabinKind: 'Cabin：Economy',
  model: 'Modules：73J',
  transitcity: 'Amsterdam',
  times: '     stay 2h55m',
  details: 'Details',
  select: 'select',
  Flight: 'Flight 18',
  Flights: 'Flight 310',
  // airport
  // 弹窗部分  相关条款
  contentTitle: "Terms of use",

  content:`Himytrip.com, a website platform, is operated by Beijing Huameiyida (himyidea) international travel Co., Ltd. whose products and services made available on the website are supplied by qualified service providers. If any user ("user" or "you") accesses, orders or uses Himytrip.com products or services (hereinafter collectively referred to as "Services") on this website, Himytrip’s affiliates or other mobile Apps or software of Himytrip.com (hereinafter collectively referred to as "Himytrip.com "), it shall be regarded as acceptance of the following service agreement (hereinafter referred to as "this Service Agreement" or "this Agreement"). Please carefully read the following terms, especially the parts in bold font. If you disagree with any of the following terms, you should immediately stop visiting this website or using services provided by it.

1. General Provisions

This Agreement includes the text hereof, individual service agreements of Himytrip.com sub-channels and the various types of rules already published or will be published on Himytrip.com , including but not limited to disclaimers, privacy policies, product booking instructions, travel contracts, account agreements and other agreements ("Other Terms"). Other Terms shall prevail if there is any inconsistency between them and this Agreement. Unless otherwise expressly stated, any service provided by Himytrip.com is bound by this Agreement.

2. Service Introduction

Beijing Huameiyida (himyidea) international travel Co., Ltd. relies on its own operation system to provide online membership services to you on the Internet. You must:
1. Provide the necessary equipment, including a PC, a modern  Internet devices.
2. Pay Internet access fee and phone bills related to this service.
Considering the importance of Himytrip.com’s online membership services, You shall agree:
1. To provide detailed and accurate personal information in a timely manner.
2. Constantly update registration information to meet the requirements of timely, detailed and accurate information.  

In addition, you may authorize Beijing Huameiyida (himyidea) international travel Co., Ltd to disclose your basic information to third parties, but Beijing Huameiyida (himyidea) international travel Co., Ltd shall not disclose your supplementary information, unless
1. You require Beijing Huameiyida (himyidea) international travel Co., Ltd. or authorize someone to disclose the information via email.
2. Beijing Huameiyida (himyidea) international travel Co., Ltd. is required to disclose your personal information by relevant laws and procedures.
If you provide inaccurate information, Beijing Huameiyida (himyidea) international travel Co., Ltd. shall reserve the right to end your right to access to Himytrip.com online membership services. 
While using the Himytrip.com membership services, you shall also agree to receive the various types of information provided by Himytrip.com membership services, including but not limited to service and marketing information such as EDM, SMS, Himytrip.com App push, PC advertisements etc.

3. Modification of Terms of Service

Himytrip.com will modify this Agreement if necessary. Himytrip.com shall make an announcement in advance on this webpage and its corresponding pages if it intends to make or modify this Agreement and/or various rules to provide you with Internet-based or mobile services. You shall periodically log into this page and other relevant pages for the latest agreement version. The modified agreement and rules will automatically take effect after being published on this page and related pages for seven days. If you disagree with the modifications made, you should immediately stop visiting Himytrip.com or using Himytrip.com services. Your use of Himytrip.com services after 7 days since the modification shall be deemed as your acceptance of the modified agreement and rules.

4. Change, Interruption and Termination of Services

You fully understand and agree that as Himytrip.com services involves Internet, mobile communications and other services, Himytrip.com services may be affected by unstable factors in all aspects. Therefore, Himytrip.com will not assume any liability of compensation for your losses resulting from service interruption, cancellation or termination risks caused by any force majeure, computer virus or hacker attack, system instability, your location, your shutdown of computers, GSM network, Internet, communication circuits and other reasons beyond the prediction or control of Himytrip.com . 
Himytrip.com needs to regularly or irregularly repair or maintain the Internet service platform or its related equipment, and shall not bear any liability for Internet service (including paid Internet service) interruption in a reasonable period caused by such circumstances, but shall try its best to make prior notification. 
You fully understand and agree that, except as otherwise provided herein, Himytrip.com may change, interrupt or terminate some or all of the Internet services because of their special nature and needs not bear any responsibility provided that it shall try its best to provide prior notification and give you time to process any related interests.

5. Rules of Use

You must comply with relevant laws and regulations of the People's Republic of China when using Himytrip.com services and agree not to use the services for any illegal or improper activities including but not limited to: 
1.The Information that goes against basic principles set forth in the Constitution; 
2. Information that endangers national security, leaks state secrets, subverts state power and undermines national unity;
3. Information that damages the honor and interest of the country;
4. Information that instigates ethnic hatred, ethnic discrimination and undermines ethnic solidarity; 
5. Information that undermines the country’s religious polices and promotes cults and feudal superstitions; 
6. Information that spreads rumor, disrupts social order and undermines social stability; 
7. Information that spreads obscenity, pornography, gambling, violence, murder, terrorism or abets crimes;
8. Information that insults or defames others and infringes the legal rights of others;
9. Information that contains false, harmful, coercive, privacy-infringing, harassing, aggressive, slanderous, vulgar, obscene content or other morally objectionable content;
10. Information that’s restricted or prohibited by Chinese laws, rules, regulations, ordinances and any other specifications with legal force. 


User cannot use the internet service system for any unlawful purpose including but not limited to:

 1. Accessing or using of the computer network resources without permission; deleting, modifying or adding functions of the computer information network without permission; deleting, modifying or adding without permission the data and applications that are stored, processed or transmitted in the computer information network; intentional production, dissemination of computer viruses and other destructive procedures; other behaviors that endanger security of the computer information network. 
2. Commercial use of any data on Himytrip.com, including but not limited to the use of resources displayed on Himytrip.com in any way such as duplication or distribution without the prior written consent of Himytrip.com.
3. Interfering or attempting to interfere with the normal operation of Himytrip.com or any transaction, activity that is conducted on Himytrip.com, or taking any action that would result in unreasonable huge data load on Himytrip.com’s network equipment by using any devices, software, routine program or other measures. 
4. Acts of unfair competition in violation of the principle of good faith, or maliciously placing orders or engaging in fictitious transactions and other malicious acts that disrupt Himytrip.com’s transaction order. 
5. Other activities not related to online transactions.

6. Copyright Statement

Any member’s acceptance of the terms of this Agreement shall mean that he/she will exclusively transfer without compensation the copyrights of any form of information published on this website to Himytrip.com’s operator, including but not limited to rights of reproduction, distribution rights, rental rights, exhibition rights, performance rights, rights of showing, broadcasting rights, the right of information distribution through network, filming rights, adaptation rights, translation rights, compilation rights and other transferable rights that should be owned by the copyrighter, and that such member allows Himytrip.com the right to initiate separate proceedings against any infringement and to receive full compensation.

This Agreement constitutes a written agreement as set forth in Article 25 of the Copyright Law, the legal effect of which shall also applies to any work or content protected by the Copyright Law published by the user on Himytrip.com, regardless of whether such content is formed before or after the signing of this Agreement. Each member agrees and is fully aware of the above terms, and will not in any form publish or authorize other websites and media (other than websites of Himytrip.com’s affiliates) to use any information published on Himytrip.com. At the same time, Himytrip.com reserves the right to remove all kinds of non-compliant information on the website without informing its members.

Beijing Huameiyida (himyidea) international travel Co., Ltd. is the operator of Himytrip.com, entitled with the copyrights of all content and resources of this website, who are protected by China’s laws and regulations on intellectual property rights, and the interpretation and modification rights in statements made on this website; without the express written consent of Beijing Huameiyida (himyidea) international travel Co., Ltd., any entity or person shall not wholly or partially copy, repost or cite such content and resources in any form or in any wording, otherwise, the company will take legal actions to impose any respective legal liabilities.

All information on Himytrip.com is protected by the Copyright Law of f the People's Republic of China, any applicable laws and regulations and all international treaties on intellectual property rights that China has joined. Himytrip.com has formulated relevant measures and procedures to protect the legitimate rights and interests of the intellectual property right owners. When any copyright owner and/or the right holder which in accordance with any applicable laws may exercise the right of information distribution through network (hereinafter referred to collectively as the "Right Holder") find any potential infringement on its right of information distribution through network, the Right Holder shall send a written notice of right to Himytrip.com first and provide a valid proof of the ownership of its right. Himytrip.com will then take measures to delete the relevant content according to any applicable laws and regulations. Himytrip.com shall have the copyright of comments and articles submitted by you by logging into Himytrip.com or any other information edited and published on Himytrip.com , and shall have the right to use any information (including but not limited to pictures and travel notes) published by you on Himytrip.com on this website and the websites of Himytrip.com’s affiliates. If other browsers would like to repost any information published by the user on Himytrip.com, prior approval from Himytrip.com and the user shall be obtained; otherwise, it shall be deemed as an infringement. Without the permission of Himytrip.com, any other third party shall not reproduce, repost or use in any form the previously mentioned comments or articles. This website is not responsible for any claim etc. caused by the violation of this legal statement by the user or any other third party.

Himytrip.com does not guarantee the accuracy and completeness of external links provided for your convenience. For any copyright issues or other problems that may arise therefrom, please call or email this website. Immediate deletion or modification will be made after verification.

7. User Privacy System

User privacy respect is a basic policy of Beijing Huameiyida (himyidea) international travel Co., Ltd. so as an addition to terms on user’s personal registration data as set forth in Article 2 above, Beijing Huameiyida (himyidea) international travel Co., Ltd. will not make public, edit or disclose your supplementary information and non-public information stored in Himytrip.com’s membership services, except in circumstances as required by laws or where Beijing Huameiyida (himyidea) international travel Co., Ltd. believes in good faith that disclosure of such letters are necessary in the following four situations: 
1. Necessary disclosures to abide by relevant laws and regulations and comply with Himytrip.com’s legal membership service procedures. 
2. Necessary disclosures to keep and maintain Himytrip.com’s trademark ownership. 
3. Necessary disclosures in emergencies to safeguard the privacy of users and the public.
4. Necessary disclosures to meet other related requirements.

Special authorization

You fully understand and irrevocably grant Himytrip.com and its affiliates, business partners the following rights for free: 
1. Himytrip.com’s affiliates or business partners may allow Himytrip.com users to log into websites of Himytrip.com’s affiliates or business partners and use their services. All behaviors of Himytrip.com users on websites of Himytrip.com’s affiliates or business partners shall comply with this Agreement, announced rules as well as instructions and guidelines for proper use of services. In order to realize the above functions, you agree that Himytrip.com may synchronize your Himytrip.com registration information, transaction/payment data etc. to systems of its affiliates or business partners and allow their use of such information, data, etc.. 
2. To provide you with the same services when you log into Himytrip.com with your user ID and password with any of Himytrip.com’s affiliates or business partners, you agree that Himytrip.com may synchronize your registration information, transaction/payment data etc. with Himytrip.com’s affiliates or business partners to Himytrip.com systems and allow the use of such information, data, etc by Himytrip.com systems, and you will not for this reason pursue responsibilities of Himytrip.com and Himytrip.com’s affiliates or business partners. 
3. To ensure the security of transactions, Himytrip.com and its affiliates and business partners will be allowed to conduct data analysis on your user information and make commercial use of the analysis results. 
4. Himytrip.com will get your relevant information from affiliates and business partners. For example, when you make a reservation or booking through Himytrip.com’s affiliates or business partners, the booking information you provide may be sent to Himytrip.com to enable it to process your order and ensure a successful reservation or booking. For another example, Himytrip.com will allow you to log into your Himytrip.com account through your social media account after you agree to establish a link between the two (you authorize the social platform). 
5. While enjoying all the services provided by Himytrip.com, you authorize and agree that Himytrip.com may send business information to your email, mobile phone and mailing address etc., including but not limited to the latest Himytrip.com product information and promotional information. If you choose not to receive the various information services from Himytrip.com, you may make proper settings as guided by Himytrip.com to refuse such information services.

8. User Account, Password and Security

You will get a password and an account number after you become a registered user. You will take full responsibility for your improper safekeeping of your account and password. In addition, you will be solely responsible for all acts and behaviors done with your account. You may change your password and icon at any time, or you may choose to cancel the old account and reopen a new one. Any illegal use of your account or security loopholes found shall be immediately reported to Beijing Huameiyida (himyidea) international travel Co., Ltd. Please keep your password properly and do not tell others about it. You will be responsible for any loss caused by your improper safekeeping of password. You will have rights in and take responsibilities for all activities, actions and things you done using your account in accordance with any applicable laws, and shall not in any form transfer your account, authorize others to use your account and trade your accounts with others. Given the unique nature of Internet services, Himytrip.com has no responsibilities to check and verify whether it’s you that are using your user name and password but will only verify if the user name and password used are consistent with those saved in the database. Any person may log into Himytrip.com and enjoy all services it offers as long as the user name and password used are consistent with those saved in the database. Therefore, even if you think it’s not you that logged into the account, Himytrip.com shall not assume any responsibility arising therefrom.

9. Refusal of Guarantee

You expressly agree that you shall bear on your own the risk of using email services. Beijing Huameiyida (himyidea) international travel Co., Ltd expressly declares that it will not provide any type of guarantee, express or implied, but will not limit commercially implied guarantees, appropriate guarantees of specific purposes and proper guarantees of compliance. Beijing Huameiyida (himyidea) international travel Co., Ltd does not guarantee that services will definitely satisfy users’ requirements, nor does it guarantee that services will not be interrupted, or guarantee about the timeliness, accuracy or errorless of services. Beijing Huameiyida (himyidea) international travel Co., Ltd refuses to provide any guarantee, including guarantees about the accurate, timely and smooth transmission of information. You understand and accept that you make your own decisions concerning any information or resource (downloaded or obtained through Himytrip.com’s membership services) and will bear all risks and responsibilities of system damage or data loss. You will not receive from Beijing Huameiyida (himyidea) international travel Co., Ltd oral or written comments or information, and will not make expressed guarantees here.

10. Limited Liability

Beijing Huameiyida (himyidea) international travel Co., Ltd. will not be responsible for any direct, indirect, incidental, special and consequential damages caused by improper use of online membership services, illegal use of services or change in information supplied by users. These damages will tarnish the image of Beijing Huameiyida (himyidea) international travel Co., Ltd., so the company has informed and mentioned the possibility of these damages.

11. Storage and Restrictions of Himytrip.com’s Online Membership Service Information

Beijing Huameiyida (himyidea) international travel Co., Ltd will not take responsibility for the failed deletion or storage of information posted by you. The company does not set upper limit for the amount of information transmitted but reserves the right to determine whether your behavior complies with the requirements and spirit of Himytrip.com’s terms and conditions of online membership services. If you violate the service terms and conditions, your membership account will be suspended. The copyright of all articles on this website are owned jointly by the original author andBeijing Huameiyida (himyidea) international travel Co., Ltd. Anyone who would like to repost articles published on the website must obtain authorization from the original author and Beijing Huameiyida (himyidea) international travel Co., Ltd

12. User Management

You are solely responsible for the content you post. You shall use services in accordance with all local laws, national laws, and international legal standards applicable to website services. You must comply with the following rules: 
1. Comply with any applicable Chinese laws and regulations when publishing information; 
2. Do not use the online membership services for any illegal purposes; 
3. Do not interfere with or disturb Internet services. 
4. Comply with all network protocols, rules and procedures in relation to the use of online membership services.

Online membership services mainly include sending and receiving information through the Internet, so national laws, regulations, policies and procedures related to the Internet, guide your behaviors. You must promise not to spread any illegal, harassing, slanderous, abusive, threatening, harmful, vulgar, obscene information and resources. Also, you shall not disseminate any information that incites others to conduct criminal behaviors, shall not spread information that encourages domestic adverse situations and involves national security, or information that does not comply with local laws and regulations, national and international laws. Unauthorized access to other computer systems is prohibited. Where your behavior does not comply with the aforesaid service terms and conditions, Beijing Huameiyida (himyidea) international travel Co., Ltd. will immediately cancel your account at its sole discretion. You need to take legal responsibility for your behavior while using online membership services. In case you spread and distribute reactionary, pornographic or other information in violation of national laws when using online membership services, the systematic records of such services may be used as evidence of your violation of law.

13. Protection

You agree to protect and defend the interests of all personnel of Beijing Huameiyida (himyidea) international travel Co., Ltd and will pay legal fees incurred by your use of services beyond the scope of services, the liquidated damages caused by violation of the terms of services, as well as the compensation claimed for others’ use of your computers, account and other intellectual property rights.

14. End of Services

You or Beijing Huameiyida (himyidea) international travel Co., Ltd. may discontinue the website services at any time based on the actual situation. Beijing Huameiyida (himyidea) international travel Co., Ltd may terminate the services without liabilities to any individual or third party. In case you disagree with any service terms and conditions or hold objections to any subsequent modifications of such terms and conditions, or are dissatisfied with Himytrip.com’s online membership services, you only have the following rights of recourse: 
1. No longer using Himytrip.com’s membership services. 
2. Ending your eligibility for using Himytrip.com’s online membership services.
3. Notifying Beijing Huameiyida (himyidea) international travel Co., Ltd., Ltd. to stop your user service. After the end of user service, your right to use Himytrip.com’s online membership services will be immediately terminated. From then on, Beijing Huameiyida (himyidea) international travel Co., Ltd. will no longer bear any obligation to you.

15. Notices

All notices to users can be sent via email or ordinary letters. Beijing Huameiyida (himyidea) international travel Co., Ltd will send notice to users via email services to notify them of any modifications to the terms and conditions of services, change of services or other important matters.

16. Advertising Planning

You may place promotional materials in your posts or participate in ad planning so to display your products on Himytrip.com for free. Any such promotion, including description about cargo transportation, payment, services, commercial terms, guarantees and advertisements only involve you and the advertising agency. Beijing Huameiyida (himyidea) international travel Co., Ltd. shall not assume any liabilities and Himytrip.com’s online membership services do not include the obligation to be responsible for advertisement sales.

17. Ownership of Email Content

User-defined content includes texts, software, sounds, photos, videos and graphics; all information in the advertisements; all content in emails; business information provided by users through Himytrip.com’s online membership services. All these contents are protected by copyright, trademark, label and other property ownership laws. Therefore, a user can only use such information under the authorization of Beijing Huameiyida (himyidea) international travel Co., Ltd. and the advertising agency, and shall not without authorization reduplicate or reproduce such information, or create derivative products based on such information.

18. Applicable Laws

This Service Agreement shall be consistent with the laws including its rules of conflicts where the website located. Both the user and Beijing Huameiyida (himyidea) international travel Co., Ltd agree to the jurisdiction of the court. If any term of service conflicts with laws, such term shall be reinterpreted in a manner as closely as possible to the respective laws, while the rest terms shall remain the legal effect and impact on users. Any dispute arising from the interpretation of this Service Agreement and the user’s ordering of any product on Himytrip.com shall be submitted to Shanghai Changning District People’s Court for resolution.`
}