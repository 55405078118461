/**
 * 深拷贝函数
 * @param {Array | Object} obj
 * @returns {Array | Object}
 */
export const deepCopy = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  let copy;
  if (Array.isArray(obj)) {
    copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
  } else {
    copy = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
  }
  return copy;
};

/**
 * 节流函数
 * @param {Function} fn
 * @param {Time} delay
 * @returns {void}
 */
export const throttle = (fn, delay) => {
  let isAnimal = false;
  return function () {
    if (isAnimal) return;
    isAnimal = true;
    setTimeout(() => {
      fn.apply(this, arguments);
      isAnimal = false;
    }, delay);
  };
};

/**
 * 防抖函数
 * @param {Function} fn 
 * @param {Time} delay 
 * @returns {void}
 */
export const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};
