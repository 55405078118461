/****   request.js   ****/
// 导入axios
import axios from "axios";
// 使用element-ui Message做消息提醒
// import { Message } from "element-ui";
import Notification from '@/utils/resetNotification';
import Cookies from "@/utils/cookies";
import router from "@/router";
import store from '@/store'
import { logout } from "../api";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//1. 创建新的axios实例，
// console.log("process.env", process.env);
const service = axios.create({
  // 公共接口
  baseURL: process.env.NODE_ENV === "development" ? "/api" : "",
  // baseURL: 'http://192.168.20.78:8080',
  // baseURL: '/api',
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 60 * 1000,
  withCredentials: false,
});

// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers = {
      //  'Content-Type':'application/json' //配置请求头
    };
    if (Cookies.get("token") && Cookies.get("token") !== '') {
      config.headers['Authorization'] = encipher()
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
function encipher() {
  const symbols = '~!@#$%^&*()_+{}":?><;.,';
  let list = [
    String.fromCharCode(Math.floor(Math.random() * 26 + 65)),
    String.fromCharCode(Math.floor(Math.random() * 26 + 96)),
    String.fromCharCode(Math.floor(Math.random() * 10 + 48)),
    symbols[Math.floor(Math.random() * symbols.length)]
  ]

  let str = ''
  for (let i = 0; i < 8; i++) {
    str += list[Math.floor(Math.random() * 4)]
  }
  return str
}

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.config?.headers?.Authorization) {
      Cookies.set("token", response.config.headers.Authorization);
    }
    return response;
  },
  (error) => {
    if (error.response.data.code == 401) {
      setTimeout(async () => {
        Cookies.remove("token");
        sessionStorage.removeItem("mail");
        await logout();
      }, 200)


      store.dispatch('login/set_loginshow', true)
      if (router.path !== '/') {
        router.push("/");
      }
    }
    if (error && error.response) {
      error.message = error.response.data.msg || error.response.data.Msg || 'the request failed, please try again.';
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        // Message.error(
        //   "The server response timeout, please refresh the current page"
        // );
        Notification({
          title: "error",
          message: "The server response timeout, please refresh the current page",
          type: "error",
        });

      }
      error.message = "Connect to the server failed";
    }

    // Message.error(error.message);
    Notification({
      title: "error",
      message: error.message,
      type: "error",
    });
    return Promise.resolve(error.response);
  }
);
export default service;
