/**
 * 处理Notification弹框
 * 一直显示在nav下面10px的位置
 */

import { debounce } from "@/utils/utils";

export default {
  created() {
    $(window).resize(debounce(this.imgLoad, 500));
  },
  mounted() {
    this.$nextTick(() => {
      this.setNotificationTop();
    });
  },
  methods: {
    imgLoad() {
      setTimeout(() => {
        this.setNotificationTop();
      }, 50);
    },
    setNotificationTop() {
        const top = this.$(".nav").outerHeight();
        document.body.style.setProperty("--notification-top", `${top + 10}px`);
      },
  },
};
