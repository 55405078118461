const emailRules = () => {
  const verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
  const handlerEmailRules = (rule, value, callback) => {
    if (!value) {
      return callback(new Error("Please enter email"));
    }
    if (!verify.test(value)) {
      return callback(new Error("Please enter your account number correctly"));
    }
    callback();
  };
  return handlerEmailRules;
};
const passwordRules = (password) => {
  const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{6,})$/;
  return pattern.test(password);
};


export { emailRules, passwordRules };
