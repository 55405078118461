import Vue from "vue";
import Vuex from "vuex";
import login from './login'
import register from './register'
import common from './common'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login: {
      ...login
    },
    register: {
      ...register
    },
    common: {
      ...common
    }
  },
});
