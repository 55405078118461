import Vue from "vue";
import Vuex from "vuex";
import { getInfo, logout } from "../api/index";
import Cookies from "@/utils/cookies";
// import { Message } from "element-ui";
import Notification from '@/utils/resetNotification';
import router from "@/router";
Vue.use(Vuex);

const state = {
  loginStatus: false, // 是否展示登录框
  loginShow: true, // 是否已经登录
  userInfo: {}, // 登录信息
};
const mutations = {
  SET_LOGINSTATUS(state, value) {
    if (!value) {
      window.sessionStorage.removeItem("isSelectClick");
    }
    window.sessionStorage.removeItem("toInformation");
    state.loginStatus = value;
  },
  SET_LOGINSHOW(state, value) {
    state.loginShow = value;
  },
  async SET_USERINFO(state, value) {
    const { data: res, status } = await getInfo()
    if (status === 200) {
      window.sessionStorage.setItem("mail", res.data.email);
      if (res.data.sex == 0) {
        res.data.sex = ''
      }
      state.userInfo = res.data;
      // console.log(state.userInfo)
    }
  },
  async SET_LOGOUT(state) {
    // state.userInfo = value;
    const res = await logout();
    if (res.status === 200) {
      if (res.data.code === 200) {
        Cookies.remove("token");
        // Message.success("Exit successfully");
        Notification({
          title: "success",
          message: "Exit successfully",
          type: "success",
          // duration: 0,
        });
        state.userInfo = {};
        // sessionStorage.clear();
        sessionStorage.removeItem("mail");
        state.loginShow = true;
        router.push("/");

        // this.$router.push("home");
      } else {
        // Message.error(res.data.Msg)
        Notification({
          title: "error",
          message: res.data.Msg,
          type: "error",
        });
      }
    } else {
      // Message.error(res.statusText)
      Notification({
        title: "error",
        message: res.statusText,
        type: "error",
      });
    }
    // } else {
    // Message.error(res.data.Msg)
    // }
    // } else {
    // Message.error(res.statusText)
    // }
  },
};
const actions = {
  set_loginstatus(context, value) {
    context.commit("SET_LOGINSTATUS", value);
  },
  set_loginshow(context, value) {
    context.commit("SET_LOGINSHOW", value);
  },
  set_userInfo(context, value) {
    context.commit("SET_USERINFO", value);
  },
  set_logout(context) {
    context.commit("SET_LOGOUT");
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
