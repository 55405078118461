import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";

import "lib-flexible/flexible";
// import '@/utils/rem';
import { message } from "@/utils/resetMessage";
import notification from "@/utils/resetNotification";
import "@/styles/index.scss";
import ElementUI from "element-ui";
// 修改默认值
ElementUI.Dialog.props.closeOnClickModal.default = false;
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import axios from "axios";
// 引入jQuery、bootstrap
import $ from "jquery";
import "bootstrap";
// 引入bootstrap样式
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import MyDivider from "./components/common/MyDivider.vue";
import MyTabs from "@/components/common/tabs/tabs.vue";
import myAutocomplete from "@/components/common/autocomplete/autocomplete.vue";

// 移动端打印
// import VConsole from 'vconsole'
// new VConsole();

ElementUI.Dialog.props.lockScroll.default = false;
Vue.component(MyDivider.name, MyDivider);
Vue.component(MyTabs.name, MyTabs);
Vue.component(myAutocomplete.name, myAutocomplete);

// 全局注册 $
Vue.prototype.$ = $;
// import api from '@/api/index.js'
Vue.prototype.$axios = axios;
// Vue.prototype.$api = api
Vue.use(VueI18n); // 通过插件的形式挂载
Vue.use(ElementUI, { locale });
Vue.prototype.$message = message;
Vue.prototype.$notify = notification;
// const router = new VueRouter({
//   mode: 'history',
// })

const i18n = new VueI18n({
  locale: "en-US", // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    "zh-CN": require("./i18n/lang/zh"), // 中文语言包
    "en-US": require("./i18n/lang/en"), // 英文语言包
    "tc-TC": require("./i18n/lang/tc"), // 繁体语言包
  },
});
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
