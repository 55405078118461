<template>
  <el-dialog
    title=""
    custom-class="editPassword-form"
    :visible.sync="editPwdStatus"
    v-if="editPwdStatus"
    :before-close="beforeClose"
  >
    <div class="editPassword">
      <div class="editPassword-theClues">Change password</div>
      <el-form
        ref="editPasswordForm"
        :rules="rules"
        :model="editPasswordForm"
        label-width="0px"
      >
        <el-form-item prop="phone">
          <el-input
            :value="editPasswordForm.phone"
            placeholder="emil"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item prop="oldPwd">
          <el-input
            v-model="editPasswordForm.oldPwd"
            show-password
            placeholder="Old password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPwd">
          <el-input
            v-model="editPasswordForm.newPwd"
            show-password
            placeholder="New password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="success"
            :loading="loading"
            class="editPassword-btn"
            @click="setSubmit"
            >Save</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { passwordRules } from "@/utils/rules";
import { userPwd } from "@/api/index";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!passwordRules(value)) {
        callback(
          new Error(
            "Password at least 6 characters (including letters and numbers)"
          )
        );
      } else {
        callback();
      }
    };
    return {
      editPasswordForm: {
        phone: window.sessionStorage.getItem("mail"),
        oldPwd: "",
        newPwd: "",
      },
      loading: false,
      codeLoading: false,
      rules: {
        oldPwd: [
          {
            required: true,
            message: "Please repeat the oldPassword",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
        newPwd: [
          {
            required: true,
            message: "Please repeat the newPassword",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      timer: null,
      num: 60,
      isCode: false,
    };
  },
  computed: {
    editPwdStatus() {
      return this.$store.state.register.editPwdStatus;
    },
    user_info() {
      return this.$store.state.login.userInfo;
    },
  },
  created() {
    this.$set(this.editPasswordForm, "phone", this.user_info.mail);
  },
  methods: {
    beforeClose() {
      this.$store.dispatch("register/set_editPwdStatus", false);
    },
    setSubmit() {
      this.$refs["editPasswordForm"].validate((valid) => {
        if (valid) {
          this.goSubmit();
        } else {
          return false;
        }
      });
    },
    outLogin() {
      sessionStorage.clear();
      this.$store.dispatch("login/set_loginshow", true);
      this.$store.dispatch("login/set_loginstatus", true);
      this.loginPopover = false;
    },
    goSubmit() {
      userPwd(this.editPasswordForm).then((res) => {
        const results = res.data;
        const Statuing = JSON.parse(results.match(/(.*?){"d":null}/)[1]);
        if (Statuing.status == 200) {
          this.$alert("Password changed successfully", {
            confirmButtonText: "Confirm",
          }).then(() => {
            this.beforeClose();
            this.outLogin();
          });
        } else {
          // this.$message.error("Old password error");
          this.$notify({
            title: "error",
            message: "Old password error",
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./editPassword.scss";
</style>
